export default function fontVisibilitySettings(functionName: string) {
  const name = functionName;
  const controller = new AbortController();
  const inputs = window.fwWcag["global-variables"].shadow.querySelectorAll(".settings-font-visibility input");

  for (const input of inputs) {
    const key = input.id.replace(`${name}-`, ``);
    const parent = input.parentNode instanceof Element ? input.parentNode as HTMLElement : undefined;

    // Add correct value to input
    if (key === "readable-font") {
      input.checked = window.fwWcag["user-config"].functions[name].settings[key].value;
    } else {
      input.value = window.fwWcag["user-config"].functions[name].settings[key].value;

      // set as variable
      parent?.style.setProperty("--input-value", `${Math.round((Number(input.value) - Number(input.min)) / (Number(input.max) - Number(input.min)) * 100)}`);
    }

    // Create event listeners
    input.addEventListener("input", () => {
      if (input) {
        // Set new value in userConfig
        if (input.type === "checkbox") {
          window.fwWcag["user-config"].functions[name].settings[key].value = input.checked;
        } else {
          window.fwWcag["user-config"].functions[name].settings[key].value = input.value;

          // set as variable
          parent?.style.setProperty("--input-value", `${Math.round(((Number.parseFloat(input.value) - Number.parseFloat(input.min)) / (Number.parseFloat(input.max) - Number.parseFloat(input.min))) * 100)}`);
        }

        // Update userConfig
        window.fwWcag.methods["helper-functions"]["update-user-config"]();

        // update styles
        window.fwWcag.methods["helper-functions"]["remove-wcag-styles"](name).then(() => {
          window.fwWcag.methods["helper-functions"]["update-wcag-selectors"](name).then(() => {
            window.fwWcag.methods["helper-functions"]["update-wcag-styles"](name);
          });
        });
      }
    }, { signal: controller.signal });
  }

  // Add controller to event listeners object
  window.fwWcag["global-variables"]["event-listeners"][name] = {
    input: controller
  }
}
