export default function initializeJS() {
  (function handleWcagFunctions() {
    // JS for all functions
    for (const functionElement of window.fwWcag["global-variables"].shadow.querySelectorAll(".function-list .function")) {
      const functionName = functionElement.dataset.fwWcagAction;

      // Check if WCAG function is active, then set it
      if (window.fwWcag["user-config"].functions[functionName].active) {
        window.fwWcag.methods["helper-functions"]["enable-wcag-function"](functionElement);
      }

      // Click functions for all WCAG functions
      functionElement.addEventListener("click", () => {
        if (window.fwWcag["user-config"].functions[functionName].active) {
          window.fwWcag.methods["helper-functions"]["disable-wcag-function"](functionElement);
        } else {
          window.fwWcag.methods["helper-functions"]["enable-wcag-function"](functionElement);
        }
      });

      // Check if settings of WCAG function are opened, then set it
      if (window.fwWcag["user-config"].functions[functionName]["settings-opened"]) {
        window.fwWcag.methods["helper-functions"]["enable-settings"](functionName);
      }

      // Click functions for settings per function
      const settingsToggler = functionElement.parentNode.querySelector(".settings-toggler");
      if (settingsToggler) {
        settingsToggler.addEventListener("click", () => {
          window.fwWcag.methods["helper-functions"]["enable-settings"](functionName);
        });
      }
    }
  })();

  (function handleWcagClickFunctions() {
    // Click function for open/close wcag
    for (const item of window.fwWcag["global-variables"].shadow.querySelectorAll("[data-fw-wcag-action='toggle-wcag']")) {
      item.addEventListener("click", () => window.fwWcag.methods["helper-functions"]["toggle-wcag"]());
    }

    // Click function for closing settings
    window.fwWcag["global-variables"].shadow.querySelector(".settings .settings-header .close").addEventListener("click", () => window.fwWcag.methods["helper-functions"]["disable-settings"]());

    // Click function for resetting everything
    window.fwWcag["global-variables"].shadow.querySelector(".functions .functions-header .reset").addEventListener("click", () => window.fwWcag.methods["helper-functions"]["reset-functions"]());

    // Click function for resetting settings
    window.fwWcag["global-variables"].shadow.querySelector(".settings .settings-header .reset").addEventListener("click", () => {
      const activeSetting = window.fwWcag["global-variables"].shadow.querySelector(".settings .settings.active");

      if (activeSetting) {
        window.fwWcag.methods["helper-functions"]["reset-settings"](activeSetting.getAttribute("id").replace("settings-", ""));
      }
    });
  })();

  (function handleWcagVisibility() {
    if (!window.fwWcag.methods["helper-functions"]["is-mobile"]()) {
      // Hover function for displaying description of visibility keycode of wcag
      const toggleWcag = window.fwWcag["global-variables"].shadow.querySelector(".head [data-fw-wcag-action='toggle-wcag']");
      const popover = toggleWcag.querySelector(".popover");

      toggleWcag.addEventListener("mouseover", () => {
        popover.classList.add("active");
      });

      toggleWcag.addEventListener("mouseleave", () => {
        popover.classList.remove("active");
      });
    }
  })();

  (function handleWcagStates() {
    // Check if wcag is opened
    window.fwWcag["user-config"]["wcag-opened"] ? window.fwWcag.methods["helper-functions"]["toggle-wcag"](false) : "";

    // Check if wcag is visible
    window.fwWcag["user-config"]["wcag-visible"] ? window.fwWcag.methods["helper-functions"]["toggle-visibility"](false) : "";
  })();

  (function handleWcagKeyCombinations() {
    // Keydown Events
    window.addEventListener("keydown", (event) => {
      if(event.key !== undefined && event.altKey) {
          // ALT Key Events

          switch(event.key) {
            case "1": {
              window.fwWcag.methods["helper-functions"]["toggle-wcag"]();
              break;
            }
            case "2": {
              window.fwWcag.methods["helper-functions"]["toggle-visibility"]();
              break;
            }
            default: {
              break;
            }
          }
        }
    });
  })();

  (function handleWcagWindows() {
    const shadow = window.fwWcag["global-variables"].shadow;
    const movableWindows = {
      "functions": {
        "selectors": [...shadow.querySelectorAll(".functions .functions-header, .functions .functions-header .functions-title")],
        "window": shadow.querySelector(".functions")
      },
      "settings": {
        "selectors": [...shadow.querySelectorAll(".settings .settings-header, .settings .settings-header .settings-title")],
        "window": shadow.querySelector(".settings")
      }
    }

    // set window positions
    window.fwWcag.methods["helper-functions"]["set-window-positions"]();

    for (const [type, movableWindow] of Object.entries(movableWindows)) {
      function setWindowSizes() {
        const shadow = window.fwWcag["global-variables"].shadow;
        shadow.host.style.setProperty(`--${type}-width`, `${movableWindow.window.offsetWidth}px`);
        shadow.host.style.setProperty(`--${type}-height`, `${movableWindow.window.offsetHeight}px`);
      }

      // set window sizes
      setWindowSizes();

      // observer for window sizes
      const observer = new MutationObserver(() => {
        setWindowSizes();
      });

      observer.observe(movableWindow.window, {
        attributes: false,
        childList: true,
        subtree: true
      });

      // event listener for window sizes
      window.addEventListener("resize", setWindowSizes);

      // drag functions for windows
      window.addEventListener("mousedown", (mouseDownEvent) => {
        const controller = new AbortController();
        const windowWidth = Number.parseInt(getComputedStyle(document.documentElement).width.replace("px", ""));
        const windowHeight = window.innerHeight;
        const movableWindowWidth = movableWindow.window.offsetWidth;
        const movableWindowHeight = movableWindow.window.offsetHeight;

        if (movableWindow.selectors.includes(shadow.elementFromPoint(mouseDownEvent.clientX, mouseDownEvent.clientY))) {
          const windowPos = movableWindow.window.getBoundingClientRect();
          const mouseElementPosDiffX = mouseDownEvent.clientX - windowPos.x;
          const mouseElementPosDiffY = mouseDownEvent.clientY - windowPos.y;

          window.addEventListener("mousemove", (mouseMoveEvent) => {
            if ((mouseMoveEvent.clientX - mouseElementPosDiffX) > 0 && (mouseMoveEvent.clientX - mouseElementPosDiffX + movableWindowWidth) < windowWidth) {
              window.fwWcag["user-config"][`${type}-position`].x = `${(mouseMoveEvent.clientX - mouseElementPosDiffX) / windowWidth * 100}%`;
            }

            if ((mouseMoveEvent.clientY - mouseElementPosDiffY) > 0 && (mouseMoveEvent.clientY - mouseElementPosDiffY + movableWindowHeight) < windowHeight) {
              window.fwWcag["user-config"][`${type}-position`].y = `${(mouseMoveEvent.clientY - mouseElementPosDiffY) / windowHeight * 100}%`;
            }

            // set window positions
            window.fwWcag.methods["helper-functions"]["set-window-positions"]();

            // update user config
            window.fwWcag.methods["helper-functions"]["update-user-config"]();
          }, { signal: controller.signal });
        }

        window.addEventListener("mouseup", () => {
          controller.abort();
        });
      });
    }
  })();

  (function handleWcagPositions() {
    const wrapper = window.fwWcag["global-variables"].wrapper;
    wrapper.style.setProperty("--head-position-x", window.fwWcag["global-config"]["toggle-position"].x);
    wrapper.style.setProperty("--head-position-y", window.fwWcag["global-config"]["toggle-position"].y);
    wrapper.style.setProperty("--head-position-mobile-x", window.fwWcag["global-config"]["toggle-position-mobile"].x);
    wrapper.style.setProperty("--head-position-mobile-y", window.fwWcag["global-config"]["toggle-position-mobile"].y);
  })();
}
