import { autoContrastMode, autoContrastModeClass } from "./functions/wcag-functions/auto-contrast-mode";
import { contrastMode, contrastModeClass } from "./functions/wcag-functions/contrast-mode";
import { darkMode, darkModeClass } from "./functions/wcag-functions/dark-mode";
import { blueLightFilter, blueLightFilterClass } from "./functions/wcag-functions/blue-light-filter";
import { grayScale, grayScaleClass } from "./functions/wcag-functions/gray-scale";
import { saturationMode, saturationModeClass } from "./functions/wcag-functions/saturation-mode";
import { colorBlindness, redColorBlindnessClass, greenColorBlindnessClass, blueColorBlindnessClass } from "./functions/wcag-functions/color-blindness";
import { fontVisibilty, fontVisibilityClass } from "./functions/wcag-functions/font-visibility";
import { biggerCursor, biggerCursorClass } from "./functions/wcag-functions/bigger-cursor";

import genericSettings from "./functions/settings-functions/generic-settings";
import fontVisibilitySettings from "./functions/settings-functions/font-visibility-settings";
import biggerCursorSettings from "./functions/settings-functions/bigger-cursor-settings";

import initializeHTML from "./functions/initialization-functions/initialize-html";
import initializeJS from "./functions/initialization-functions/initialize-js";
import initializeObserver from "./functions/initialization-functions/initialize-observer";
import initializeWcag from "./functions/initialization-functions/initialize-wcag";
import initializeWcagFunction from "./functions/initialization-functions/initialize-wcag-function";

import * as helperFunctions from "./functions/helper-functions";

export default {
  "wcag-functions": {
    "auto-contrast-mode": {
      "function": autoContrastMode,
      "class": autoContrastModeClass
    },
    "contrast-mode": {
      "function": contrastMode,
      "class": contrastModeClass,
      "settings": genericSettings
    },
    "dark-mode": {
      "function": darkMode,
      "class": darkModeClass
    },
    "blue-light-filter": {
      "function": blueLightFilter,
      "class": blueLightFilterClass,
      "settings": genericSettings
    },
    "gray-scale": {
      "function": grayScale,
      "class": grayScaleClass,
      "settings": genericSettings
    },
    "saturation-mode": {
      "function": saturationMode,
      "class": saturationModeClass,
      "settings": genericSettings
    },
    "color-blindness": {
      "function": colorBlindness,
      "class": {
        "red": redColorBlindnessClass,
        "green": greenColorBlindnessClass,
        "blue": blueColorBlindnessClass
      }
    },
    "font-visibility": {
      "function": fontVisibilty,
      "class": fontVisibilityClass,
      "settings": fontVisibilitySettings
    },
    "bigger-cursor": {
      "function": biggerCursor,
      "class": biggerCursorClass,
      "settings": biggerCursorSettings
    }
  },
  "initialization-functions": {
    "initialize-html": initializeHTML,
    "initialize-js": initializeJS,
    "initialize-observer": initializeObserver,
    "initialize-wcag": initializeWcag,
    "initialize-wcag-function": initializeWcagFunction
  },
  "helper-functions": {
    "enable-settings": helperFunctions.enableSettings,
    "enable-transitions": helperFunctions.enableTransitions,
    "enable-wcag-function": helperFunctions.enableWcagFunction,
    "disable-event-listeners": helperFunctions.disableEventListeners,
    "disable-settings": helperFunctions.disableSettings,
    "disable-transitions": helperFunctions.disableTransitions,
    "disable-wcag-function": helperFunctions.disableWcagFunction,
    "is-mobile": helperFunctions.isMobile,
    "reset-functions": helperFunctions.resetFunctions,
    "reset-settings": helperFunctions.resetSettings,
    "set-window-positions": helperFunctions.setWindowPositions,
    "toggle-visibility": helperFunctions.toggleVisibility,
    "toggle-wcag": helperFunctions.toggleWcag,
    "update-user-config": helperFunctions.updateUserConfig,
    "deep-copy": helperFunctions.deepCopy,
    "disable-observer": helperFunctions.disableObserver,
    "remove-wcag-styles": helperFunctions.removeWcagStyles,
    "update-wcag-selectors": helperFunctions.updateWcagSelectors,
    "update-wcag-styles": helperFunctions.updateWcagStyles
  }
}
