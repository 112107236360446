import icons from "../../icons";
import methods from "../../methods";
import { fwWcagType } from "@/types";

export default async function initializeWcag() {
  class fwWcag {
    [`defaults`]: FwWcagConfig
    [`global-config`]: FwWcagConfig[`global-config`]
    [`user-config`]: FwWcagConfig[`user-config`]
    [`global-variables`]: fwWcagType[`global-variables`]
    [`methods`]: fwWcagType[`methods`]
    [`head`]: fwWcagType[`head`]
    [`functions`]: fwWcagType[`functions`]
    [`settings`]: fwWcagType[`settings`]

    constructor(defaults: FwWcagConfig) {
      this[`defaults`] = defaults;

      this[`global-config`] = this[`defaults`][`global-config`];

      const userConfigCookieMatch = document.cookie.match(/fwWcagUserConfig=([^;]*)/);
      const userConfigCookieDecoded = userConfigCookieMatch ? JSON.parse(userConfigCookieMatch[1]) : undefined;

      this[`user-config`] = userConfigCookieDecoded?.version === this[`defaults`][`user-config`].version
        ? userConfigCookieDecoded
        : this[`defaults`][`user-config`];

      this[`global-variables`] = {
        [`wrapper`]: document.createElement('div'),
        [`shadow`]: undefined,
        [`html`]: undefined,
        [`event-listeners`]: {},
        [`observers`]: {},
        [`defined-languages`]: [
          `de`,
          `en`
        ]
      }

      this[`methods`] = methods;

      this[`head`] = {
        [`toggle-wcag`]: {
          [`name`]: `toggle-wcag`,
          [`icon`]: icons[`head`][`toggle-wcag`],
          [`de`]: {
            [`description`]: `WCAG - Einstellungen öffnen / schließen`
          },
          [`en`]: {
            [`description`]: `Open / close WCAG Settings`
          }
        },
        [`toggle-visibility`]: {
          [`name`]: `toggle - visibility`,
          [`icon`]: icons[`head`][`toggle-visibility`],
          [`de`]: {
            [`description`]: `Drücke <b>ALT + 2</b> um WCAG Einstellungen ein- / auszublenden`
          },
          [`en`]: {
            [`description`]: `Press <b>ALT + 2</b> to toggle the visibility of WCAG options`
          }
        },
        [`advertisement`]: {
          [`name`]: `advertisement`,
          [`icon`]: icons[`head`][`advertisement`],
          [`de`]: {
            [`description`]: `WCAG Tools von <a href="https://www.futureweb.at" target="_blank">www.futureweb.at</a>`
          },
          [`en`]: {
            [`description`]: `WCAG Tools by <a href="https://www.futureweb.at" target='_blank'>www.futureweb.at</a>`
          }
        }
      }

      this[`functions`] = {
        [`general`]: {
          [`de`]: {
            [`title`]: `Barrierefreiheit - Optionen`,
            [`settings-title-suffix`]: `Einstellungen`
          },
          [`en`]: {
            [`title`]: `Accesibility - options`,
            [`settings-title-suffix`]: `Settings`
          }
        },
        [`wcag`]: {
          [`auto-contrast-mode`]: new this[`methods`][`wcag-functions`][`auto-contrast-mode`][`class`](this),
          [`contrast-mode`]: new this[`methods`][`wcag-functions`][`contrast-mode`][`class`](this),
          [`dark-mode`]: new this[`methods`][`wcag-functions`][`dark-mode`][`class`](this),
          [`blue-light-filter`]: new this[`methods`][`wcag-functions`][`blue-light-filter`][`class`](this),
          [`gray-scale`]: new this[`methods`][`wcag-functions`][`gray-scale`][`class`](this),
          [`saturation-mode`]: new this[`methods`][`wcag-functions`][`saturation-mode`][`class`](this),
          [`red-color-blindness`]: new this[`methods`][`wcag-functions`][`color-blindness`][`class`][`red`](this),
          [`green-color-blindness`]: new this[`methods`][`wcag-functions`][`color-blindness`][`class`][`green`](this),
          [`blue-color-blindness`]: new this[`methods`][`wcag-functions`][`color-blindness`][`class`][`blue`](this),
          [`font-visibility`]: new this[`methods`][`wcag-functions`][`font-visibility`][`class`](this),
          [`bigger-cursor`]: new this[`methods`][`wcag-functions`][`bigger-cursor`][`class`](this)
        }
      }

      this[`settings`] = {
        [`close`]: {
          [`name`]: `close`,
          [`icon`]: icons[`settings`][`close`],
          [`de`]: {
            [`description`]: `Einstellungen schließen`
          },
          [`en`]: {
            [`description`]: `Close Settings`
          }
        },
        [`settings`]: {
          [`name`]: `settings`,
          [`icon`]: icons[`settings`][`settings`],
          [`de`]: {
            [`description`]: `Einstellungen öffnen`
          },
          [`en`]: {
            [`description`]: `Open Settings`
          }
        },
        [`reset`]: {
          [`name`]: `reset`,
          [`icon`]: icons[`settings`][`reset`],
          [`de`]: {
            [`description`]: `Einstellungen zurücksetzen`
          },
          [`en`]: {
            [`description`]: `Reset Settings`
          }
        }
      }
    }
  }

  window.fwWcag = new fwWcag(window.fwWcagConfig);

  // Add shadow DOM to wrapper
  window.fwWcag["global-variables"]["shadow"] = window.fwWcag["global-variables"].wrapper.attachShadow({ mode: "closed" });

  // Append and configure shadow DOM
  document.body.append(window.fwWcag["global-variables"].wrapper);
  window.fwWcag["global-variables"].wrapper.setAttribute("id", "fw-wcag-wrapper");

  // Set HTML
  window.fwWcag["global-variables"].shadow.innerHTML = window.fwWcag.methods["initialization-functions"]["initialize-html"]();

  // Set HTML as global variable
  window.fwWcag["global-variables"]["html"] = window.fwWcag["global-variables"].shadow.querySelector("#fw-wcag");

  // Initialize JS
  window.fwWcag["global-variables"].shadow.querySelector("#css").addEventListener("load", () => {
    window.fwWcag.methods["initialization-functions"]["initialize-js"]();
  });
}
