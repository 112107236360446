export default function initializeObserver(functionName: string) {
  // Create observer
  window.fwWcag["global-variables"].observers[window.fwWcag.functions.wcag[functionName].name] = new MutationObserver((mutations) => {
    (async function() {
      async function checkMutation() {
        return new Promise((resolve, reject) => {
          for (const mutation of mutations) {
            const addedNodes = mutation.addedNodes;

            for (const addedNode of addedNodes) {
              const target = addedNode as HTMLElement;

              // check if mutation doesnt include a fw-wcag element that get's set inside of the function, otherwise we are gonna have an infinite loop
              for (const key in target.dataset) {
                if (
                  target.dataset.hasOwnProperty(key) &&
                  key.replaceAll(/([a-z])([A-Z])/g, '$1-$2').toLowerCase().includes(functionName) ||
                  target.id.includes(functionName)
                ) {
                  reject(false);
                }
              }
              resolve(true);
            }
          }
        });
      }

      try {
        await checkMutation();
        await window.fwWcag.methods["helper-functions"]["update-wcag-selectors"](window.fwWcag.functions.wcag[functionName].name);
        await window.fwWcag.methods["helper-functions"]["update-wcag-styles"](window.fwWcag.functions.wcag[functionName].name);
      } catch {
        // Mutation was invalid
      }
    })();
  });

  window.fwWcag["global-variables"].observers[window.fwWcag.functions.wcag[functionName].name].observe(document.body, {
    attributes: false,
    childList: true,
    subtree: true
  });
}
