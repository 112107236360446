import icons from "@/icons";
import { fwWcagType } from "@/types";

function contrastMode() {
  const name = window.fwWcag.functions.wcag["contrast-mode"].name;
  const input = window.fwWcag["global-variables"].shadow.querySelector(`#settings-${name} #${name}-range`);
  const controller = new AbortController();
  const documentElement = document.documentElement;

  // set Attribute for removing filter afterwards
  documentElement.setAttribute(`data-fw-wcag-filter-${name}`, "true");

  // Set blue light filter value
  documentElement.style.filter = `contrast(${window.fwWcag["user-config"].functions[name].settings.value})`;

  // Add event listener for dynamic changes
  input.addEventListener("input", (field: {
    target: {
      value: number;
    };
  }) => {
      // set new background opacity
      documentElement.style.filter = `contrast(${field.target.value})`;
  }, { signal: controller.signal });

  // Add controller to event listeners object
  window.fwWcag["global-variables"]["event-listeners"][`function-${name}`] = {
      input: controller
  }

  // Add controller to event listeners object
  window.fwWcag["global-variables"]["event-listeners"][`function-${name}`] = {
      input: controller
  }
}

class contrastModeClass {
  [`name`]: string
  [`function`]: fwWcagType[`methods`][`wcag-functions`][`contrast-mode`][`function`]
  [`settings-function`]: fwWcagType[`methods`][`wcag-functions`][`contrast-mode`][`settings`]
  [`observer`]: boolean
  [`mobile`]: boolean
  [`conflict`]: string[]
  [`settings`]: string
  [`icon`]: string
  [`de`]: {
    [`title`]: string
    [`settings-description`]: string
  }
  [`en`]: {
    [`title`]: string
    [`settings-description`]: string
  }

  constructor(instance: fwWcagType) {
    this[`name`] = `contrast-mode`
    this[`function`] = instance[`methods`][`wcag-functions`][`contrast-mode`][`function`]
    this[`settings-function`] = instance[`methods`][`wcag-functions`][`contrast-mode`][`settings`]
    this[`observer`] = false
    this[`mobile`] = true
    this[`conflict`] = [
      `gray-scale`,
      `blue-light-filter`,
      `saturation-mode`,
      `red-color-blindness`,
      `green-color-blindness`,
      `blue-color-blindness`
    ]
    this[`de`] = {
      [`title`]: `Kontrast-Modus`,
      [`settings-description`]: `Intensität`
    }
    this[`en`] = {
      [`title`]: `Contrast-Mode`,
      [`settings-description`]: `Intensity`
    }
    this[`icon`] = icons[`functions`][`contrast-mode`]
    this[`settings`] = `
      <div class="input-range-wrapper">
          <label for="contrast-mode-range">${this[instance[`user-config`][`lang`]][`settings-description`]}</label>
          <input id="contrast-mode-range" type="range" min="1" max="2" step="0.01" />
      </div>
    `
  }
}

export {
  contrastMode,
  contrastModeClass
}
