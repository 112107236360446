export default function initializeHTML() {
  if (!window.fwWcag["global-variables"]["defined-languages"].includes(window.fwWcag["user-config"].lang)) {
    window.fwWcag["user-config"].lang = "en";

    // update user config
    window.fwWcag.methods["helper-functions"]["update-user-config"]();
  }

  let functions = `
    <div class="functions-wrapper">
      <div class="functions-header">
        <button
          class="${window.fwWcag["settings"]["reset"].name}"
          aria-label="${window.fwWcag["settings"]["reset"][window.fwWcag["user-config"].lang].description}"
          data-fw-wcag-action="${window.fwWcag["settings"]["reset"].name}"
        >
          ${window.fwWcag["settings"]["reset"].icon}
        </button>
        <div class="functions-title">
          ${window.fwWcag["functions"].general[window.fwWcag["user-config"].lang].title}
        </div>
        <button
          class="${window.fwWcag["settings"]["close"].name}"
          aria-label="${window.fwWcag["settings"]["close"][window.fwWcag["user-config"].lang].description}"
          data-fw-wcag-action="${window.fwWcag["head"]["toggle-wcag"].name}"
        >
          ${window.fwWcag["settings"]["close"].icon}
        </button>
      </div>
      <div class="functions-inner">
        <ul class="function-list">
  `;
  for (const [key] of Object.entries(window.fwWcag["functions"].wcag)) {
    functions += `
      ${window.fwWcag["user-config"].functions[key] && !(window.fwWcag.methods["helper-functions"]["is-mobile"]() && !window.fwWcag["functions"].wcag[key].mobile)
        ? `
          <!-- ${window.fwWcag["functions"].wcag[key][window.fwWcag["user-config"].lang].title} -->
          <li>
            <button class="function" data-fw-wcag-action="${window.fwWcag["functions"].wcag[key].name}">
              <span class="icon">
                ${window.fwWcag["functions"].wcag[key].icon}
              </span>
              <span class="title">
                ${window.fwWcag["functions"].wcag[key][window.fwWcag["user-config"].lang].title}
              </span>
            </button>
            ${window.fwWcag["functions"].wcag[key]["settings"]
              ? `
                <button class="settings-toggler" aria-label="${window.fwWcag["settings"].settings[window.fwWcag["user-config"].lang].description}">
                  ${window.fwWcag["settings"].settings.icon}
                </button>
              `
              : ``
            }
          </li>
        `
        : ``
      }
    `
  }
  functions += `
        </ul>
      </div>
    </div>
  `;

  let settings = `
    <div class="settings-wrapper">
      <div class="settings-header">
        <button
          class="${window.fwWcag["settings"]["reset"].name}"
          aria-label="${window.fwWcag["settings"]["reset"][window.fwWcag["user-config"].lang].description}"
          data-fw-wcag-action="${window.fwWcag["settings"]["reset"].name}"
        >
          ${window.fwWcag["settings"]["reset"].icon}
        </button>
        <div class="settings-title"></div>
        <button
          class="${window.fwWcag["settings"]["close"].name}"
          aria-label="${window.fwWcag["settings"]["close"][window.fwWcag["user-config"].lang].description}"
          data-fw-wcag-action="${window.fwWcag["settings"]["close"].name}"
        >
          ${window.fwWcag["settings"]["close"].icon}
        </button>
      </div>
      <div class="settings-inner">
  `;
  for (const [key] of Object.entries(window.fwWcag["functions"].wcag)) {
    settings += `
      ${window.fwWcag["user-config"].functions[key]
        ? `
          ${window.fwWcag["functions"].wcag[key].settings
            ? `
              <!-- ${window.fwWcag["functions"].wcag[key][window.fwWcag["user-config"].lang].title} SETTINGS -->
              <div id="settings-${window.fwWcag["functions"].wcag[key].name}" class="settings">
                ${window.fwWcag["functions"].wcag[key].settings}
              </div>
            `
            : ``
          }
        `
        : ``
      }
    `
  }
  settings += `
      </div>
    </div>
  `;

  return `
    <link id="css" href="${window.fwWcag["global-config"].css}" rel="stylesheet" type="text/css">
    <style id="css-overrides">
      #fw-wcag {
        position: fixed;
      }
      ${window.fwWcag["global-config"]["css-overrides"] ?? ``}
    </style>
    <div id="fw-wcag">
      <div class="head">
        <button
          class="${window.fwWcag["head"]["toggle-wcag"].name}"
          aria-label="${window.fwWcag["head"]["toggle-wcag"][window.fwWcag["user-config"].lang].description}"
          data-fw-wcag-action="${window.fwWcag["head"]["toggle-wcag"].name}"
        >
          <span class="icon">
            ${window.fwWcag["head"]["toggle-wcag"].icon}
          </span>
          <span class="popover">
            <span class="popover-icon">
              ${window.fwWcag["head"]["toggle-visibility"].icon}
            </span>
            <span class="popover-description">
              ${window.fwWcag["head"]["toggle-visibility"][window.fwWcag["user-config"].lang].description}
            </span>
          </span>
        </button>
      </div>
      <div class="functions">
        ${functions}
      </div>
      <div class="settings">
        ${settings}
      </div>
      ${window.fwWcag["global-config"].unauthorized
        ? `
          <div class="advertisement">
            <div class="icon">
              ${window.fwWcag["head"]["advertisement"].icon}
            </div>
            <div class="text">
              ${window.fwWcag["head"]["advertisement"][window.fwWcag["user-config"].lang].description}
            </div>
          </div>
        `
        : ``
      }
    </div>
  `;
}
