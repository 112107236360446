// event listeners

function disableEventListeners(name: string) {
  if (window.fwWcag["global-variables"]["event-listeners"][name] && Object.keys(window.fwWcag["global-variables"]["event-listeners"][name]).length > 0) {
    for (const [key] of Object.entries(window.fwWcag["global-variables"]["event-listeners"][name])) {
      window.fwWcag["global-variables"]["event-listeners"][name][key].abort();
      delete window.fwWcag["global-variables"]["event-listeners"][name];
    }
  }
}

// observers

function disableObserver(functionName: string) {
  if (window.fwWcag.functions.wcag[functionName].observer) {
    window.fwWcag["global-variables"].observers[functionName].disconnect();
    delete window.fwWcag["global-variables"].observers[functionName];
  }
}

// settings

function enableSettings(functionName: string) {
  let activeSetting = window.fwWcag["global-variables"].shadow.querySelector(".settings .settings.active");
  const settings = window.fwWcag["global-variables"].shadow.querySelector(`#settings-${functionName}`);

  // override with actual function name if existent
  if (activeSetting) {
    activeSetting = activeSetting.getAttribute("id").replace("settings-", "");
  }

  // disable other settings
  window.fwWcag.methods["helper-functions"]["disable-settings"]();

  // add new setting
  if (settings && functionName !== activeSetting) {
    // set opened settings in user-config
    window.fwWcag["user-config"].functions[functionName]["settings-opened"] = true;

    // update user Config
    window.fwWcag.methods["helper-functions"]["update-user-config"]();

    // add class
    settings.classList.add("active");

    // add class to settings-wrapper
    window.fwWcag["global-variables"].shadow.querySelector(".settings").classList.add("active");

    // add title
    window.fwWcag["global-variables"].shadow.querySelector(".settings .settings-title").innerHTML = `${window.fwWcag.functions.wcag[functionName][window.fwWcag["user-config"].lang].title} - ${window.fwWcag.functions.general[window.fwWcag["user-config"].lang]["settings-title-suffix"]}`;

    // add event listeners
    window.fwWcag.functions.wcag[functionName]["settings-function"](functionName);
  }
}

function disableSettings() {
  const activeSetting = window.fwWcag["global-variables"].shadow.querySelector(".settings .settings.active");

  // disable all settings
  if (activeSetting) {
    const name = activeSetting.getAttribute("id").replace("settings-", "");

    // remove class
    activeSetting.classList.remove("active");

    // remove class from settings-wrapper
    window.fwWcag["global-variables"].shadow.querySelector(".settings").classList.remove("active");

    // set closed settings in user-config
    window.fwWcag["user-config"].functions[name]["settings-opened"] = false;

    // update user Config
    window.fwWcag.methods["helper-functions"]["update-user-config"]();

    // remove event listeners
    window.fwWcag.methods["helper-functions"]["disable-event-listeners"](name);
  }
}

// transitions

function enableTransitions() {
  // Remove stylesheets
  for (const element of document.querySelectorAll(".fw-wcag-disable-transitions")) {
    element.remove();
  }

  // Remove all wcag attributes from elements
  for (const element of document.querySelectorAll("[data-fw-wcag-disable-transitions]")) {
    const finalElement = element as HTMLElement;
    delete finalElement.dataset.fwWcagDisableTransitions;
  }
}

function disableTransitions(selector: HTMLElement) {
  const excludedTagNames = new Set(["SCRIPT", "LINK", "HEAD", "HTML", "META", "TITLE", "STYLE"]);
  const head = document.head;
  const style = document.createElement('style');
  style.classList.add('fw-wcag-disable-transitions');
  const css = `
      [data-fw-wcag-disable-transitions] {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
      }
  `;

  // Create array of elements that should be modified
  const allElements = Array.prototype.slice.call(selector.querySelectorAll("*:not([data-fw-wcag-disable-transition], #fw-wcag-wrapper)"));
  if (!selector.getAttribute("[data-fw-wcag-disable-transitions]")) {
    allElements.push(selector);
  }

  // Create styles
  for (const element of allElements) {
    if (!excludedTagNames.has(element.tagName)) {
      const elementStyles = getComputedStyle(element);
      const transitionDuration = elementStyles.transitionDuration;

      // Check if transition is higher than 0s
      if (transitionDuration !== "0s") {
        element.dataset.fwWcagDisableTransitions = "true";
      }
    }
  }

  // Append stylesheet
  style.append(document.createTextNode(css));
  head.append(style);
}

// wcag

function enableWcagFunction(functionElement: HTMLElement) {
  const functionName = functionElement.dataset.fwWcagAction;
  const parent = functionElement.parentNode instanceof Element ? functionElement.parentNode : undefined;

  if(functionName) {
    // Remove conflicting functions
    for (const element of window.fwWcag["global-variables"].shadow.querySelectorAll(".function-list li.active .function")) {
      const activeFunction = element.dataset.fwWcagAction;
      window.fwWcag.functions.wcag[functionName].conflict.includes(activeFunction) ?  window.fwWcag.methods["helper-functions"]["disable-wcag-function"](element) : "";
    }

    // Set new function
    window.fwWcag.methods["initialization-functions"]["initialize-wcag-function"](window.fwWcag.functions.wcag[functionName].name);

    // Set function property to active
    window.fwWcag["user-config"].functions[functionName].active = true;

    // Update the userConfig
    window.fwWcag.methods["helper-functions"]["update-user-config"]();

    // Add active to function name
    parent?.classList.add("active");

    // enable settings
    if(!window.fwWcag["user-config"].functions[functionName]["settings-opened"]) {
      window.fwWcag.methods["helper-functions"]["enable-settings"](functionName);
    }
  }
}

function disableWcagFunction(functionElement: HTMLElement) {
  const functionName = functionElement.dataset.fwWcagAction;
  const parent = functionElement.parentNode instanceof Element ? functionElement.parentNode : undefined;

  if(functionName && parent) {
    // Disable function
    window.fwWcag["user-config"].functions[functionName].active = false;

    // Remove active class from function
    parent?.classList.remove("active");

    // Disconnect Observer
    window.fwWcag.methods["helper-functions"]["disable-observer"](functionName)

    // disable event listeners
    window.fwWcag.methods["helper-functions"]["disable-event-listeners"](`function-${functionName}`);

    // remove all styles
    window.fwWcag.methods["helper-functions"]["remove-wcag-styles"](functionName);

    // disable settings
    window.fwWcag.methods["helper-functions"]["disable-settings"]();

    // Update userConfig
    window.fwWcag.methods["helper-functions"]["update-user-config"]();
  }
}


// styles etc.

async function updateWcagStyles(functionName: string) {
  if(window.fwWcag["user-config"].functions[functionName].active && window.fwWcag.functions.wcag[functionName]["sub-functions"]["update-styles"]) {
    // disable all transitions
    window.fwWcag.methods["helper-functions"]["disable-transitions"](document.body);

    window.fwWcag.functions.wcag[functionName]["sub-functions"]["update-styles"]().then(() => {
      // enable transitions back
      window.fwWcag.methods["helper-functions"]["enable-transitions"]();
    });
  }
}

async function updateWcagSelectors(functionName: string) {
  if(window.fwWcag["user-config"].functions[functionName].active && window.fwWcag.functions.wcag[functionName]["sub-functions"]["update-selectors"]) {
    // disable all transitions
    window.fwWcag.methods["helper-functions"]["disable-transitions"](document.body);

    window.fwWcag.functions.wcag[functionName]["sub-functions"]["update-selectors"]().then(() => {
      // enable transitions back
      window.fwWcag.methods["helper-functions"]["enable-transitions"]();
    });
  }
}

async function removeWcagStyles(functionName: string) {
  // Remove stylesheets
  for (const element of document.querySelectorAll(`.fw-wcag-styles-${functionName}`)) {
    element.remove();
  }

  // Remove all IDs from elements
  const idsInsideFwWcag = window.fwWcag["global-variables"].shadow.querySelectorAll(`[id^='data-fw-wcag-styles-id-${functionName}']`)
  const idsInsideDocument = document.querySelectorAll(`[id^='data-fw-wcag-styles-id-${functionName}']`);
  const idsCombinedElements = [...idsInsideFwWcag, ...idsInsideDocument];

  for (const element of idsCombinedElements) {
    element.removeAttribute("id");
  }

  // Remove all attributes from elements
  const attributesInsideFwWcag = window.fwWcag["global-variables"].shadow.querySelectorAll(`[data-fw-wcag-styles-id-${functionName}]`)
  const attributesInsideDocument = document.querySelectorAll(`[data-fw-wcag-styles-id-${functionName}]`);
  const attributesCombinedElements = [...attributesInsideFwWcag, ...attributesInsideDocument];

  for (const element of attributesCombinedElements) {
    element.removeAttribute(`data-fw-wcag-styles-id-${functionName}`);
  }

  // Remove overlays
  const shadowOverlays = window.fwWcag["global-variables"].shadow.querySelectorAll(`[data-fw-wcag-overlay-${functionName}]`);
  const DOMOverlays = document.querySelectorAll(`[data-fw-wcag-overlay-${functionName}]`);
  const overlays = [...shadowOverlays, ...DOMOverlays]
  for (const element of overlays) {
    element.remove();
  }

  // Remove filter from body and html
  for (const element of [document.documentElement, document.body]) {
    if (element.getAttribute(`data-fw-wcag-filter-${functionName}`)) {
      element.removeAttribute(`data-fw-wcag-filter-${functionName}`);
      element.style.filter = "";
    }
  }
}

// reset

function resetFunctions() {
  const activeFunctions = window.fwWcag["global-variables"].shadow.querySelectorAll(".function-list li.active .function");

  if (activeFunctions) {
    for (const name of activeFunctions) {
      window.fwWcag.methods["helper-functions"]["disable-wcag-function"](name);
    }
  }
}

function resetSettings(name: string) {
  if (name) {
    const functionElement = window.fwWcag["global-variables"].shadow.querySelector(`.function-list .function[data-fw-wcag-action="${name}"`);

    // Set new settings
    window.fwWcag["user-config"].functions[name].settings = deepCopy(window.fwWcag["defaults"]["user-config"].functions[name].settings);

    // update user config
    updateUserConfig();

    if (functionElement.parentNode.classList.contains("active")) {
      // reload function
      window.fwWcag.methods["helper-functions"]["disable-wcag-function"](functionElement);
      window.fwWcag.methods["helper-functions"]["enable-wcag-function"](functionElement);
    }

    // reload settings
    window.fwWcag.methods["helper-functions"]["disable-settings"]();
    window.fwWcag.methods["helper-functions"]["enable-settings"](name);
  }
}

// globally relevant

function updateUserConfig() {
  document.cookie = "fwWcagUserConfig=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

  const time = Date.now();
  const expirationYears = 10;
  const expirationTime = time + (1000 * 60 * 60 * 24 * 365 * expirationYears);
  const expirationDate = new Date(expirationTime);

  document.cookie = `fwWcagUserConfig=${JSON.stringify(window.fwWcag["user-config"])}; expires=${expirationDate}; path=/; SameSite=None; Secure;`;
}

function setWindowPositions() {
  const shadow = window.fwWcag["global-variables"].shadow;

  // functions
  shadow.host.style.setProperty("--functions-position-x", window.fwWcag["user-config"]["functions-position"].x);
  shadow.host.style.setProperty("--functions-position-y", window.fwWcag["user-config"]["functions-position"].y);

  // settings
  shadow.host.style.setProperty("--settings-position-x", window.fwWcag["user-config"]["settings-position"].x);
  shadow.host.style.setProperty("--settings-position-y", window.fwWcag["user-config"]["settings-position"].y);
}

// toggles

function toggleVisibility(open: boolean = window.fwWcag["user-config"]["wcag-visible"]) {
  if (open) {
    window.fwWcag["global-variables"].html.classList.remove("visible");
  } else {
    window.fwWcag["global-variables"].html.classList.add("visible");

    // set focus
    window.fwWcag["global-variables"].shadow.querySelector("button, input").focus();
  }

  // Set in user-config
  window.fwWcag["user-config"]["wcag-visible"] = !open;

  // Update user-config
  window.fwWcag.methods["helper-functions"]["update-user-config"]();
}

function toggleWcag(open: boolean = window.fwWcag["user-config"]["wcag-opened"]) {
  if (open) {
    window.fwWcag["global-variables"].html.classList.remove("opened");
  } else {
    window.fwWcag["global-variables"].html.classList.add("opened");

    // set focus
    window.fwWcag["global-variables"].shadow.querySelector("button, input").focus();
  }

  // Set in user-config
  window.fwWcag["user-config"]["wcag-opened"] = !open;

  // Update user-config
  window.fwWcag.methods["helper-functions"]["update-user-config"]();
}

// other

function deepCopy<T>(object: T): T {
  if (typeof object !== 'object' || object === null) {
    return object; // Return primitive types or null as is
  }

  if (Array.isArray(object)) {
    // If it's an array, create a new array and recursively deep copy each element
    const newArray = object.map(item => deepCopy(item)) as unknown as T;
    return newArray;
  }

  // If it's an object, create a new object and recursively deep copy each property
  const newObject: { [key: string]: object } = {};

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      newObject[key] = deepCopy((object as { [key: string]: object })[key]);
    }
  }

  return newObject as T;
}

function isMobile() {
  return !!/android|webos|iphone|ipad|ipod|blackberry|windows phone/i.test(window.navigator.userAgent);
}

export {
  disableEventListeners,

  disableObserver,

  enableSettings,
  disableSettings,

  enableTransitions,
  disableTransitions,

  enableWcagFunction,
  disableWcagFunction,

  updateWcagSelectors,
  updateWcagStyles,
  removeWcagStyles,

  resetFunctions,
  resetSettings,

  updateUserConfig,
  setWindowPositions,

  toggleVisibility,
  toggleWcag,

  deepCopy,
  isMobile
}
