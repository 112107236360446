import icons from "../../icons";
import { fwWcagType } from "@/types";

function biggerCursor() {
  const name = window.fwWcag.functions.wcag["bigger-cursor"].name;
  const controller = new AbortController();
  const inputs = window.fwWcag["global-variables"].shadow.querySelectorAll(`#settings-${name} .${name}-radio-wrapper input`);
  const baseCursorSize = window.fwWcag["global-config"].functions[name]["base-cursor-size"];
  const canvas = document.createElement("canvas");
  const canvasContext = canvas.getContext("2d");
  let mousePositionX: number;
  let mousePositionY: number;

  // set Attribute for styles
  canvas.setAttribute(`data-fw-wcag-canvas`, "true");
  canvas.setAttribute(`data-fw-wcag-overlay-${name}`, "true");
  canvas.setAttribute("width", String(document.documentElement.clientWidth)); //window.innerWidth returns width including the scrollbar...
  canvas.setAttribute("height", String(window.innerHeight));

  // get cursor
  function getCursor(type: string, value = window.fwWcag["user-config"].functions[name].settings.value) {
    if (window.fwWcag.functions.wcag[name].cursors[type]) {
      return window.fwWcag.functions.wcag[name].cursors[type].replaceAll(/(?:width|height)="[^"]*"/g, (match: string) => {
        return `${match.split("=")[0]}="${baseCursorSize * value}"`;
      });
    }
  }

  function drawCursor(element: Element, x: number, y: number) {
    if (element === window.fwWcag["global-variables"].wrapper) {
      element = window.fwWcag["global-variables"].shadow.elementFromPoint(x, y);
    }

    const attribute = element.getAttribute(`data-fw-wcag-styles-id-${name}`);
    const nextParentElement = element.parentNode instanceof Element && element.parentNode.tagName ? element.parentNode.closest(`[data-fw-wcag-styles-id-${name}]`) : false;
    const cursor = attribute ? (attribute === "none" && nextParentElement ? nextParentElement.getAttribute(`data-fw-wcag-styles-id-${name}`) : attribute) : getComputedStyle(element).cursor;
    const type = cursor !== "pointer" && cursor !== "text" ? "normal" : cursor;
    const positionX = type === "pointer" ? 0.4 : (type === "text" ? 0.5 : 0.25);
    const positionY = type === "text" ? 0.5 : 0;
    const svg = getCursor(type);
    const img = new Image();

    // clear canvas
    canvasContext?.clearRect(0, 0, canvas.width, canvas.height);

    // set src
    img.setAttribute("src", `data:image/svg+xml;utf8,${svg.trim()}`);

    // set proportions
    img.setAttribute("width", String(window.fwWcag["user-config"].functions[name].settings.value * baseCursorSize));
    img.setAttribute("height", String(window.fwWcag["user-config"].functions[name].settings.value * baseCursorSize));

    // draw Image
    canvasContext?.drawImage(img, x - img.width * positionX, y - img.height * positionY, img.width, img.height);

    // remove pseudo image
    img.remove();

    // add attribute and/or id to element, so it has no cursor
    if (!attribute) {
      if (!element.getAttribute("id")) {
        element.setAttribute("id", `data-fw-wcag-styles-id-${name}`);
      }

      element.setAttribute(`data-fw-wcag-styles-id-${name}`, String(cursor));
    }
  }

  // Iterate over every input
  for (const input of inputs) {
    input.addEventListener("input", () => {
      if (mousePositionX && mousePositionY) {
        // draw cursor
        const element = document.elementFromPoint(mousePositionX, mousePositionY);
        if(element) {
          drawCursor(element, mousePositionX, mousePositionY);
        }
      }
    });
  }

  window.addEventListener("mousemove", (event) => {
    // draw cursor
    const element = document.elementFromPoint(event.clientX, event.clientY);
    if(element) {
      drawCursor(element, event.clientX, event.clientY);
    }

    // set mouse positions
    mousePositionX = event.clientX;
    mousePositionY = event.clientY;
  }, { signal: controller.signal });

  // Add controller to event listeners object
  window.fwWcag["global-variables"]["event-listeners"][`function-${name}`] = {
    input: controller
  }

  // Append canvas to shadow DOM
  window.fwWcag["global-variables"].shadow.append(canvas);

  // Add no cursor styles
  const head = document.head;
  const style = document.createElement('style');
  style.classList.add(`fw-wcag-styles-${name}`);
  const css = `
      [data-fw-wcag-styles-id-${name}], #data-fw-wcag-styles-id-${name} {
        cursor: none !important;
      }
  `;

  // Append stylesheet
  style.append(document.createTextNode(css));
  head.append(style);
}

class biggerCursorClass {
  [`name`]: string
  [`function`]: fwWcagType[`methods`][`wcag-functions`][`bigger-cursor`][`function`]
  [`settings-function`]: fwWcagType[`methods`][`wcag-functions`][`bigger-cursor`][`settings`]
  [`observer`]: boolean
  [`mobile`]: boolean
  [`conflict`]: string[]
  [`icon`]: string
  [`cursors`]: {
    [`normal`]: string
    [`pointer`]: string
    [`text`]: string
  }
  [`de`]: {
    [`title`]: string
  }
  [`en`]: {
    [`title`]: string
  }
  [`settings`]: string

  constructor(instance: fwWcagType) {
    this[`name`] = `bigger-cursor`
    this[`function`] = instance[`methods`][`wcag-functions`][`bigger-cursor`][`function`]
    this[`settings-function`] = instance[`methods`][`wcag-functions`][`bigger-cursor`][`settings`]
    this[`observer`] = false
    this[`mobile`] = false
    this[`conflict`] = []
    this[`icon`] = icons[`functions`][`bigger-cursor`][`icon`]
    this[`cursors`] = {
      [`normal`]: icons[`functions`][`bigger-cursor`][`normal`],
      [`pointer`]: icons[`functions`][`bigger-cursor`][`pointer`],
      [`text`]: icons[`functions`][`bigger-cursor`][`text`]
    }
    this[`de`] = {
      [`title`]: `Mauszeiger`
    }
    this[`en`] = {
      [`title`]: `Cursor`
    }
    this[`settings`] = `
      <div class="settings-bigger-cursor">
        <div class="input-radio-wrapper">
          <label for="bigger-cursor-radio-1">
            ${icons[`functions`][`bigger-cursor`][`size`].replaceAll(/effective-width|effective-height/g, "20")}
          </label>
          <input id="bigger-cursor-radio-1" type"="radio" name="bigger-cursor-radio" value="1" aria-hidden="false" />
        </div>
        <div class="input-radio-wrapper" >
          <label for= "bigger-cursor-radio-2" >
            ${icons[`functions`][`bigger-cursor`][`size`].replaceAll(/effective-width|effective-height/g, "25")}
          </label>
          <input id="bigger-cursor-radio-2" type="radio" name="bigger-cursor-radio" value="1.25" aria - hidden="false" />
        </div>
        <div class="input-radio-wrapper" >
          <label for= "bigger-cursor-radio-3" >
            ${icons[`functions`][`bigger-cursor`][`size`].replaceAll(/effective-width|effective-height/g, "30")}
          </label>
        <input id="bigger-cursor-radio-3" type="radio" name="bigger-cursor-radio" value="1.5" aria - hidden="false" />
        </div>
        <div class="input-radio-wrapper">
          <label for= "bigger-cursor-radio-4">
            ${icons[`functions`][`bigger-cursor`][`size`].replaceAll(/effective-width|effective-height/g, "35")}
          </label>
          <input id="bigger-cursor-radio-4" type="radio" name="bigger-cursor-radio" value="1.75" aria - hidden="false" />
        </div>
        <div class="input-radio-wrapper" >
          <label for= "bigger-cursor-radio-5" >
            ${icons[`functions`][`bigger-cursor`][`size`].replaceAll(/effective-width|effective-height/g, "40")}
          </label>
          <input id="bigger-cursor-radio-5" type="radio" name="bigger-cursor-radio" value="2" aria - hidden="false" />
        </div>
      </div>
    `
  }
}

export {
  biggerCursor,
  biggerCursorClass
}
