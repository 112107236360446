import initializeWcag from "@functions/initialization-functions/initialize-wcag";
import "@styles/_import.scss";
import * as Sentry from '@sentry/browser';

// init Sentry
Sentry.init({
  dsn: import.meta.env.MODE === "production"
    ? "https://c7f8bd08bc09d874500322d5dfc7031e@sentry.futureweb.at:3001/14"
    : undefined,
  tracesSampleRate: import.meta.env.MODE === "production" ? 0.25 : 1,
  replaysSessionSampleRate: import.meta.env.MODE === "production" ? 0.25 : 1,
  replaysOnErrorSampleRate: 1,
  integrations: [
    Sentry.browserTracingIntegration({
      beforeStartSpan: context => {
        return {
        ...context,
        name: `${location.hostname}`,
        };
      },
    }),
    Sentry.replayIntegration()
  ]
});

// set version
window.fwWcagConfig["user-config"].version = __FW_WCAG_VERSION__ + __FW_WCAG_GIT_COMMIT__;

document.readyState === "complete"
    ? initializeWcag()
    : window.addEventListener("load", initializeWcag);

// small init log for console
console.log(`[fw-wcag] v${__FW_WCAG_VERSION__} - ${__FW_WCAG_GIT_COMMIT__} initialized`);
