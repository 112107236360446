import icons from "@/icons";
import { fwWcagType, stylesType, elementOverrideStylesType } from "@/types";

function darkMode() {
  const name = window.fwWcag.functions.wcag["dark-mode"].name;
  const excludedTagNames = new Set(["SCRIPT", "LINK", "HEAD", "HTML", "META", "TITLE", "STYLE"]);
  const selector = document.body;
  let targetNodes: HTMLElement[];

  async function getAllImages() {
    type imageElements = {
      originalElement: HTMLElement
      elements: HTMLImageElement[]
    }

    const images: imageElements[] = [];
    const imagesRaw = targetNodes.filter(element => {
      const elementStyles = getComputedStyle(element);
      if ((element.tagName === "IMG" || elementStyles.backgroundImage !== "none") && elementStyles.maskImage === "none") {
        return element;
      }
    });

    for (const element of imagesRaw) {
      const elementStyles = getComputedStyle(element);

      if (element.tagName === "IMG") {
        const image = element as HTMLImageElement;

        // add to list
        images.push({
          originalElement: element,
          elements: [image]
        });
      }

      if (elementStyles.backgroundImage !== "none") {
        const backgroundImagesRegex = /url\(["']?(.*?)["']?\)/g;
        const backgroundImageRegex = /url\(["']?(.*?)["']?\)/;
        const backgroundImage = elementStyles.backgroundImage.match(backgroundImagesRegex);
        const imagesRaw: HTMLImageElement[] = [];

        if (backgroundImage) {
          for (const backgroundImagePath of backgroundImage) {
            const matchResult = backgroundImagePath.match(backgroundImageRegex);
            const source = matchResult ? matchResult[1] : undefined;

            if (source) {
              // create pseudo image
              const image = document.createElement("img");
              image.setAttribute("src", source);

              imagesRaw.push(image);
            }
          }

          if (backgroundImage) {
            // add to list
            images.push({
              originalElement: element,
              elements: imagesRaw
            });
          }
        }
      }
    }

    return images;
  }

  async function updateSelectors() {
    // Create array of elements that should be modified
    targetNodes = Array.prototype.slice.call(selector.querySelectorAll(`*:not([data-fw-wcag-styles-id-${name}], [id*='data-fw-wcag-styles-id-${name}'])`));
    if (!selector.getAttribute(`[data-fw-wcag-styles-id-${name}]`) && (!selector.getAttribute("id")?.includes(`data-fw-wcag-styles-id-${name}`) || !selector.getAttribute("id"))) {
      targetNodes.push(selector);
    }

    // Remove all elements inside fw-wcag
    const fwWcagElements = Array.prototype.slice.call(document.querySelectorAll("#fw-wcag-wrapper"));
    targetNodes = targetNodes.filter(value => !fwWcagElements.includes(value));
  }

  async function updateStyles() {
    const style = document.createElement('style');
    style.classList.add(`fw-wcag-styles-${name}`);
    const styles: stylesType[] = [];
    const imagesRaw = await getAllImages();
    let css = "";

    // Create styles
    for (const [key, element] of targetNodes.entries()) {
      if (!excludedTagNames.has(element.tagName)) {
        const uniqId = key + Date.now();
        const elementId = element.getAttribute("id") ?? false;
        const elementOverrideStyles: elementOverrideStylesType = {
          selector: `html ${element.tagName.toLowerCase()}${elementId ? `${elementId && !elementId.includes("data-fw-wcag-styles-id") ? `#${elementId}` : ""}[data-fw-wcag-styles-id-${name}='${uniqId}']` : `#data-fw-wcag-styles-id-${name}-${uniqId}`}`,
          styles: []
        }
        const elementStyles = getComputedStyle(element);
        const color = elementStyles.color;
        const bgColor = elementStyles.backgroundColor;
        const borderColor = elementStyles.borderColor;
        const rgbaRegex = new RegExp(/rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})(?:\s*,\s*(\d*\.?\d+))?\s*\)/);
        const darknessDegree = window.fwWcag["global-config"].functions[name]["colors"]["darkness-degree"];
        const colorLightnessLimit = window.fwWcag["global-config"].functions[name]["colors"]["lightness-limit"];
        const colorDarknessLimit = window.fwWcag["global-config"].functions[name]["colors"]["darkness-limit"];

        // Set unique selector
        elementId
          ? element.setAttribute(`data-fw-wcag-styles-id-${name}`, String(uniqId))
          : element.setAttribute("id", `data-fw-wcag-styles-id-${name}-${uniqId}`);

        // Check Images
        async function checkImage(image: HTMLImageElement) {
          return new Promise((resolve, reject) => {
            function removeImageFromArray() {
              for (const [keyRaw, imageRaw] of Object.entries(imagesRaw)) {
                const key = Number(keyRaw) as number;

                if (imageRaw.originalElement === image) {
                  imagesRaw.splice(key, 1);
                  reject();
                }
              }
            }

            if (image.loading === "lazy") {
              removeImageFromArray();
            }

            const timeout = setTimeout(() => {
              removeImageFromArray();
            }, 300);

            if (image.complete) {
              resolve(true);  // Image loaded successfully
              clearTimeout(timeout);
            }

            image.addEventListener('load', () => {
              resolve(true);  // Image loaded successfully
              clearTimeout(timeout);
            });

            image.addEventListener('error', () => {
              reject();  // Image failed to load
              clearTimeout(timeout);
            });
          });
        }

        // Color handling
        async function handleColors() {
          // Check for color, then replace it with degraded color tone / white
          if (color && color !== "rgba(0, 0, 0, 0)") {
            const colorValues = color.match(rgbaRegex)?.map(Number);

            if (
              colorValues
              &&
              (
                colorValues[1] > colorDarknessLimit ||
                colorValues[2] > colorDarknessLimit ||
                colorValues[3] > colorDarknessLimit
              )
              &&
              (
                colorValues[1] < colorLightnessLimit &&
                colorValues[2] < colorLightnessLimit &&
                colorValues[3] < colorLightnessLimit
              )
            ) {
              colorValues[1] = (colorValues[1] / darknessDegree) > 0 ? Math.ceil(colorValues[1] / darknessDegree) : 0;
              colorValues[2] = (colorValues[2] / darknessDegree) > 0 ? Math.ceil(colorValues[2] / darknessDegree) : 0;
              colorValues[3] = (colorValues[3] / darknessDegree) > 0 ? Math.ceil(colorValues[3] / darknessDegree) : 0;

              colorValues[4]
                ? elementOverrideStyles.styles.push(`color: rgba(${colorValues[1]}, ${colorValues[2]}, ${colorValues[3]}, ${colorValues[4]}) !important;`)
                : elementOverrideStyles.styles.push(`color: rgb(${colorValues[1]}, ${colorValues[2]}, ${colorValues[3]}) !important;`);
            } else {
              colorValues && colorValues[4]
                ? elementOverrideStyles.styles.push(`color: rgba(255, 255, 255, ${colorValues[4]}) !important;`)
                : elementOverrideStyles.styles.push(`color: rgb(255, 255, 255) !important;`);
            }
          }

          // Check for background-color, then replace it with degraded color tone / black
          if (bgColor && bgColor !== "rgba(0, 0, 0, 0)") {
            const bgColorValues = bgColor.match(rgbaRegex)?.map(Number);

            if (elementStyles.maskImage === "none") {
              if (
                bgColorValues &&
                bgColorValues[1] > colorLightnessLimit &&
                bgColorValues[2] > colorLightnessLimit &&
                bgColorValues[3] > colorLightnessLimit
              ) {
                bgColorValues[4]
                  ? elementOverrideStyles.styles.push(`background-color: rgba(${(255 - bgColorValues[1]) / darknessDegree}, ${(255 - bgColorValues[2]) / darknessDegree}, ${(255 - bgColorValues[3]) / darknessDegree}, ${bgColorValues[4]}) !important;`)
                  : elementOverrideStyles.styles.push(`background-color: rgb(${(255 - bgColorValues[1]) / darknessDegree}, ${(255 - bgColorValues[2]) / darknessDegree}, ${(255 - bgColorValues[3]) / darknessDegree}) !important;`);
              } else if (bgColorValues) {
                bgColorValues[1] = (bgColorValues[1] / darknessDegree) > 0 ? Math.ceil(bgColorValues[1] / darknessDegree) : 0;
                bgColorValues[2] = (bgColorValues[2] / darknessDegree) > 0 ? Math.ceil(bgColorValues[2] / darknessDegree) : 0;
                bgColorValues[3] = (bgColorValues[3] / darknessDegree) > 0 ? Math.ceil(bgColorValues[3] / darknessDegree) : 0;

                bgColorValues[4]
                  ? elementOverrideStyles.styles.push(`background-color: rgba(${bgColorValues[1]}, ${bgColorValues[2]}, ${bgColorValues[3]}, ${bgColorValues[4]}) !important;`)
                  : elementOverrideStyles.styles.push(`background-color: rgb(${bgColorValues[1]}, ${bgColorValues[2]}, ${bgColorValues[3]}) !important;`);
              }
            } else {
              if (
                bgColorValues
                &&
                (
                  bgColorValues[1] > colorDarknessLimit ||
                  bgColorValues[2] > colorDarknessLimit ||
                  bgColorValues[3] > colorDarknessLimit
                )
                &&
                (
                  bgColorValues[1] < colorLightnessLimit &&
                  bgColorValues[2] < colorLightnessLimit &&
                  bgColorValues[3] < colorLightnessLimit
                )
              ) {
                bgColorValues[1] = (bgColorValues[1] / darknessDegree) > 0 ? Math.ceil(bgColorValues[1] / darknessDegree) : 0;
                bgColorValues[2] = (bgColorValues[2] / darknessDegree) > 0 ? Math.ceil(bgColorValues[2] / darknessDegree) : 0;
                bgColorValues[3] = (bgColorValues[3] / darknessDegree) > 0 ? Math.ceil(bgColorValues[3] / darknessDegree) : 0;

                bgColorValues[4]
                  ? elementOverrideStyles.styles.push(`background-color: rgba(${bgColorValues[1]}, ${bgColorValues[2]}, ${bgColorValues[3]}, ${bgColorValues[4]}) !important;`)
                  : elementOverrideStyles.styles.push(`background-color: rgb(${bgColorValues[1]}, ${bgColorValues[2]}, ${bgColorValues[3]}) !important;`);
              } else {
                bgColorValues && bgColorValues[4]
                  ? elementOverrideStyles.styles.push(`background-color: rgba(255, 255, 255, ${bgColorValues[4]}) !important;`)
                  : elementOverrideStyles.styles.push(`background-color: rgb(255, 255, 255) !important;`);
              }
            }
          }

          // Check for border-color, then replace it with black
          if (borderColor && borderColor !== "rgba(0, 0, 0, 0)") {
            const borderColorValues = borderColor.match(rgbaRegex)?.map(Number);

            if (
              borderColorValues &&
              borderColorValues[1] > colorLightnessLimit &&
              borderColorValues[2] > colorLightnessLimit &&
              borderColorValues[3] > colorLightnessLimit
            ) {
              borderColorValues[4]
                ? elementOverrideStyles.styles.push(`border-color: rgba(${(255 - borderColorValues[1]) / darknessDegree}, ${(255 - borderColorValues[2]) / darknessDegree}, ${(255 - borderColorValues[3]) / darknessDegree}, ${borderColorValues[4]}) !important;`)
                : elementOverrideStyles.styles.push(`border-color: rgb(${(255 - borderColorValues[1]) / darknessDegree}, ${(255 - borderColorValues[2]) / darknessDegree}, ${(255 - borderColorValues[3]) / darknessDegree}) !important;`);
            } else if (borderColorValues) {
              borderColorValues[1] = (borderColorValues[1] / darknessDegree) > 0 ? Math.ceil(borderColorValues[1] / darknessDegree) : 0;
              borderColorValues[2] = (borderColorValues[2] / darknessDegree) > 0 ? Math.ceil(borderColorValues[2] / darknessDegree) : 0;
              borderColorValues[3] = (borderColorValues[3] / darknessDegree) > 0 ? Math.ceil(borderColorValues[3] / darknessDegree) : 0;

              borderColorValues[4]
                ? elementOverrideStyles.styles.push(`border-color: rgba(${borderColorValues[1]}, ${borderColorValues[2]}, ${borderColorValues[3]}, ${borderColorValues[4]}) !important;`)
                : elementOverrideStyles.styles.push(`border-color: rgb(${borderColorValues[1]}, ${borderColorValues[2]}, ${borderColorValues[3]}) !important;`);
            }
          }
        }

        // Image handling
        async function handleImages() {
          // create canvas
          const canvas = document.createElement("canvas");
          const canvasContext = canvas.getContext("2d", {
            willReadFrequently: true,
          });
          const images = imagesRaw.filter(image => {
            if (image.originalElement === element) {
              return true;
            }
          });
          let allImagesValid = true;
          const darknessLimit = window.fwWcag["global-config"].functions[name]["images"]["darkness-limit"];

          if (elementStyles.backgroundImage !== "none") {
            const backgroundImagesRegex = /url\(["']?(.*?)["']?\)/g;
            const backgroundImage = elementStyles.backgroundImage.match(backgroundImagesRegex);

            if (!backgroundImage && element.tagName !== "IMG") {
              allImagesValid = false;
            }
          }

          if (images.length > 0) {
            for (const image of images[0].elements) {
              try {
                await checkImage(image).then(loaded => {
                  if (loaded) {
                    // set canvas size, divide by 10 if big enough, since we don't need it in full resolution
                    canvas.setAttribute("width", String(image.width < 500 ? image.width / 5 : image.width / 10));
                    canvas.setAttribute("width", String(image.height < 500 ? image.height / 5 : image.height / 10));

                    // set pixel-skip or disable it if image is too small
                    const pixelSkip = image.width < 100 && image.height < 100 ? 10 : window.fwWcag["global-config"].functions[name]["images"]["pixel-skip"];

                    // draw canvas image
                    canvasContext?.drawImage(image, 0, 0, canvas.width, canvas.height);

                    const canvasData = canvasContext?.getImageData(0, 0, canvas.width, canvas.height);

                    if (canvasData) {
                      let count = 0,
                        r = 0,
                        b = 0,
                        g = 0,
                        index = -4;

                      /* we step 4 times every iteration since we have
                          r = 1
                          g = 2
                          b = 3
                          a = 4

                          pixel-skip is how many pixels we skip between checking the rgba data of a single pixel
                      */

                      while ((index += pixelSkip * 4) < canvasData.data.length) {
                        const red = index,
                          green = index + 1,
                          blue = index + 2,
                          alpha = index + 3;

                        // Check for no transparency
                        if (canvasData.data[alpha] !== 0) {
                          count++;
                          r += canvasData.data[red];
                          g += canvasData.data[green];
                          b += canvasData.data[blue];
                        }
                      }

                      // Make every image that is below r, g and b value of darknessLimit a 100% white image
                      if (
                        r / count > darknessLimit ||
                        g / count > darknessLimit ||
                        b / count > darknessLimit
                      ) {
                        allImagesValid = false;
                      }
                    }
                  }
                });
              } catch {
                // image is from another domain
                allImagesValid = false;
              }
            }
          }

          // Set style if all images are below darknessLimit
          if (allImagesValid) {
            elementOverrideStyles.styles.push("filter: brightness(0) invert(1) !important;", "color: unset !important;", "background-color: unset !important;");
          } else {
            handleColors();
          }

          // Remove element
          canvas.remove();
        }

        await ((element.tagName === "IMG" || elementStyles.backgroundImage !== "none") && elementStyles.maskImage === "none"
          ? handleImages()
          : handleColors());

        // Append Element styles to overall styles
        styles.push(elementOverrideStyles);
      }
    }

    // Create stylesheet
    for (const item of styles) {
      let itemStyles = "";
      for (const style of item.styles) {
        itemStyles += style;
      }

      css += `${item.selector} { ${itemStyles} }`;
    }

    // Append stylesheet
    if (css) {
      style.append(document.createTextNode(css));
      document.head.append(style);
    }
  }

  // Add sub-functions to array
  window.fwWcag.functions.wcag[name]["sub-functions"]["update-selectors"] = updateSelectors;
  window.fwWcag.functions.wcag[name]["sub-functions"]["update-styles"] = updateStyles;

  // Add selectors
  updateSelectors().then(() => {
    // Add styles
    updateStyles();
  });
}

class darkModeClass {
  [`name`]: string
  [`function`]: fwWcagType[`methods`][`wcag-functions`][`dark-mode`][`function`]
  [`function-parameters`]: HTMLElement
  [`sub-functions`]: object
  [`observer`]: boolean
  [`mobile`]: boolean
  [`conflict`]: string[]
  [`icon`]: string
  [`de`]: {
    [`title`]: string
  }
  [`en`]: {
    [`title`]: string
  }

  constructor(instance: fwWcagType) {
    this[`name`] = `dark-mode`
    this[`function`] = instance[`methods`][`wcag-functions`][`dark-mode`][`function`]
    this[`function-parameters`] = document.body
    this[`sub-functions`] = {}
    this[`observer`] = true
    this[`mobile`] = true
    this[`conflict`] = [
      `auto-contrast-mode`,
      `contrast-mode`
    ]
    this[`icon`] = icons[`functions`][`dark-mode`]
    this[`de`] = {
      [`title`]: `Nacht-Modus`
    }
    this[`en`] = {
      [`title`]: `Dark-Mode`
    }
  }
}

export {
  darkMode,
  darkModeClass
}
