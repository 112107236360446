export default function biggerCursorSettings(functionName: string) {
  const name = functionName;
  const controller = new AbortController();
  const inputs = window.fwWcag["global-variables"].shadow.querySelectorAll(`#settings-${name} input`);

  // Create event listeners
  for (const input of inputs) {
    // Add value to field
    if (Number.parseFloat(input.getAttribute("value")) === window.fwWcag["user-config"].functions[name].settings.value) {
      input.checked = true;
    }

    input.addEventListener("input", (event: Event) => {
      const target = event.target as HTMLInputElement;

      // Set new value in userConfig
      window.fwWcag["user-config"].functions[name].settings.value = Number.parseFloat(target.value);

      // Update userConfig
      window.fwWcag.methods["helper-functions"]["update-user-config"]();
    }, { signal: controller.signal });
  }

  // Add controller to event listeners object
  window.fwWcag["global-variables"]["event-listeners"][name] = {
    input: controller
  }
}
