export default function genericSettings(name: string) {
  const input = window.fwWcag["global-variables"].shadow.querySelector(`#settings-${name} #${name}-range`);
  const controller = new AbortController();

  // set value on input
  input.value = window.fwWcag["user-config"].functions[name].settings.value;

  // set as variable
  input.parentNode.style.setProperty("--input-value", `${Math.round((input.value - input.min) / (input.max - input.min) * 100)}`);

  // Add event listener for dynamic changes
  input.addEventListener("input", (event: Event) => {
    const target = event.target as HTMLInputElement;
    const parent = target.parentNode instanceof Element ? target.parentNode as HTMLElement : undefined;

    // Set new value in user-config
    window.fwWcag["user-config"].functions[name].settings.value = target.value;

    // set as variable
    parent?.style.setProperty("--input-value", `${Math.round((Number(target.value) - Number(target.min)) / (Number(target.max) - Number(target.min)) * 100)}`);

    // Update userConfig
      window.fwWcag.methods["helper-functions"]["update-user-config"]();
  }, { signal: controller.signal });

  // Add controller to event listeners object
  window.fwWcag["global-variables"]["event-listeners"][name] = {
    input: controller
  }
}
