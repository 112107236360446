import icons from "@/icons";
import { fwWcagType } from "@/types";

function colorBlindness(type: string) {
  const name = window.fwWcag.functions.wcag[`${type}-color-blindness`].name;
  const filter = document.createElement("div");

  // set attributes for filter
  filter.dataset.fwWcagOverlay = "true";
  filter.setAttribute(`data-fw-wcag-overlay-${name}`, "true");
  filter.style.width = "0";
  filter.style.height = "0";

  // Set filter
  filter.innerHTML = window.fwWcag.functions.wcag[name].filter;

  if(window.navigator.userAgent.includes("Firefox")) {
      // fix for firefox
      const overlay = document.createElement("div");

      // set attributes and styles for overlay
      overlay.dataset.fwWcagOverlay = "true";
      overlay.setAttribute(`data-fw-wcag-overlay-${name}`, "true");
      overlay.style.position = "fixed";
      overlay.style.width = "100vw";
      overlay.style.height = "100svh";
      overlay.style.top = "0";
      overlay.style.left = "0";
      overlay.style.zIndex = `${Number.MAX_SAFE_INTEGER}`;
      overlay.style.pointerEvents = "none";
      overlay.style.backdropFilter = `url(#fw-wcag-filter)`;

      // Append overlay to DOM
      document.documentElement.append(overlay);
  } else {
      const html = document.documentElement;

      html.style.filter = `url(#fw-wcag-filter)`;
  }

  // Append filter to DOM
  document.documentElement.append(filter);
}

class redColorBlindnessClass {
  [`name`]: string
  [`function`]: fwWcagType[`methods`][`wcag-functions`][`color-blindness`][`function`]
  [`function-parameters`]: string
  [`observer`]: boolean
  [`mobile`]: boolean
  [`conflict`]: string[]
  [`icon`]: string
  [`filter`]: string
  [`de`]: {
    [`title`]: string
  }
  [`en`]: {
    [`title`]: string
  }

  constructor(instance: fwWcagType) {
    this[`name`] = `red-color-blindness`,
      this[`function`] = instance[`methods`][`wcag-functions`][`color-blindness`][`function`],
      this[`function-parameters`] = `red`,
      this[`observer`] = false,
      this[`mobile`] = true,
      this[`conflict`] = [
        `blue-light-filter`,
        `gray-scale`,
        `saturation-mode`,
        `contrast-mode-mode`,
        `green-color-blindness`,
        `blue-color-blindness`
      ],
      this[`icon`] = icons[`functions`][`color-blindness`][`red`][`icon`],
      this[`filter`] = icons[`functions`][`color-blindness`][`red`][`filter`],
      this[`de`] = {
        [`title`]: `Rotschwäche-Modus`
      },
      this[`en`] = {
        [`title`]: `Red-Color-Blindness-Mode`
      }
  }
}

class greenColorBlindnessClass {
  [`name`]: string
  [`function`]: fwWcagType[`methods`][`wcag-functions`][`color-blindness`][`function`]
  [`function-parameters`]: string
  [`observer`]: boolean
  [`mobile`]: boolean
  [`conflict`]: string[]
  [`icon`]: string
  [`filter`]: string
  [`de`]: {
    [`title`]: string
  }
  [`en`]: {
    [`title`]: string
  }

  constructor(instance: fwWcagType) {
    this[`name`] = `green-color-blindness`,
      this[`function`] = instance[`methods`][`wcag-functions`][`color-blindness`][`function`],
      this[`function-parameters`] = `green`,
      this[`observer`] = false,
      this[`mobile`] = true,
      this[`conflict`] = [
        `blue-light-filter`,
        `gray-scale`,
        `saturation-mode`,
        `contrast-mode-mode`,
        `red-color-blindness`,
        `blue-color-blindness`
      ],
      this[`icon`] = icons[`functions`][`color-blindness`][`green`][`icon`],
      this[`filter`] = icons[`functions`][`color-blindness`][`green`][`filter`],
      this[`de`] = {
        [`title`]: `Grünschwäche-Modus`
      },
      this[`en`] = {
        [`title`]: `Green-Color-Blindness-Mode`
      }
  }
}

class blueColorBlindnessClass {
  [`name`]: string
  [`function`]: fwWcagType[`methods`][`wcag-functions`][`color-blindness`][`function`]
  [`function-parameters`]: string
  [`observer`]: boolean
  [`mobile`]: boolean
  [`conflict`]: string[]
  [`icon`]: string
  [`filter`]: string
  [`de`]: {
    [`title`]: string
  }
  [`en`]: {
    [`title`]: string
  }

  constructor(instance: fwWcagType) {
    this[`name`] = `blue-color-blindness`,
      this[`function`] = instance[`methods`][`wcag-functions`][`color-blindness`][`function`],
      this[`function-parameters`] = `blue`,
      this[`observer`] = false,
      this[`mobile`] = true,
      this[`conflict`] = [
        `blue-light-filter`,
        `gray-scale`,
        `saturation-mode`,
        `contrast-mode-mode`,
        `red-color-blindness`,
        `green-color-blindness`
      ],
      this[`icon`] = icons[`functions`][`color-blindness`][`blue`][`icon`],
      this[`filter`] = icons[`functions`][`color-blindness`][`blue`][`filter`],
      this[`de`] = {
        [`title`]: `Blauschwäche-Modus`
      },
      this[`en`] = {
        [`title`]: `Blue-Color-Blindness-Mode`
      }
  }
}

export {
  colorBlindness,
  redColorBlindnessClass,
  greenColorBlindnessClass,
  blueColorBlindnessClass
}
