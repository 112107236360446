import icons from "../../icons";
import { fwWcagType } from "@/types";

function blueLightFilter() {
  const name = window.fwWcag.functions.wcag["blue-light-filter"].name;
  const input = window.fwWcag["global-variables"].shadow.querySelector(`#settings-${name} #${name}-range`);
  const controller = new AbortController();
  const overlay = document.createElement("div");

  // set Attribute for styles
  overlay.dataset.fwWcagOverlay = "true";
  overlay.setAttribute(`data-fw-wcag-overlay-${name}`, "true");

  // Set blue light filter value
  overlay.style.backgroundColor = `rgba(255, 127, 0, ${window.fwWcag["user-config"].functions[name].settings.value})`;

  // Add event listener for dynamic changes
  input.addEventListener("input", (field: {
    target: {
      value: number;
    };
  }) => {
      // Set new background opacity
      overlay.style.backgroundColor = `rgba(255, 127, 0, ${field.target.value})`;
  }, { signal: controller.signal });

  // Add controller to event listeners object
  window.fwWcag["global-variables"]["event-listeners"][`function-${name}`] = {
      input: controller
  }

  // Append overlay to Shadow DOM
  window.fwWcag["global-variables"].shadow.append(overlay);
}

class blueLightFilterClass {
  [`name`]: string
  [`function`]: fwWcagType[`methods`][`wcag-functions`][`blue-light-filter`][`function`]
  [`settings-function`]: fwWcagType[`methods`][`wcag-functions`][`blue-light-filter`][`settings`]
  [`observer`]: boolean
  [`mobile`]: boolean
  [`conflict`]: string[]
  [`settings`]: string
  [`icon`]: string
  [`de`]: {
    [`title`]: string
    [`settings-description`]: string
  }
  [`en`]: {
    [`title`]: string
    [`settings-description`]: string
  }

  constructor(instance: fwWcagType) {
    this[`name`] = `blue-light-filter`
    this[`function`] = instance[`methods`][`wcag-functions`][`blue-light-filter`][`function`]
    this[`settings-function`] = instance[`methods`][`wcag-functions`][`blue-light-filter`][`settings`]
    this[`observer`] = false
    this[`mobile`] = true
    this[`conflict`] = [
      `gray-scale`,
      `contrast-mode`,
      `saturation-mode`,
      `red-color-blindness`,
      `green-color-blindness`,
      `blue-color-blindness`
    ]
    this[`icon`] = icons[`functions`][`blue-light-filter`]
    this[`de`] = {
      [`title`]: `Blau-Filter`,
      [`settings-description`]: `Intensität`
    }
    this[`en`] = {
      [`title`]: `Blue-Light Filter`,
      [`settings-description`]: `Intensity`
    }
    this[`settings`] = `
      <div class="input-range-wrapper">
          <label for="blue-light-filter-range">${this[instance[`defaults`][`user-config`][`lang`]][`settings-description`]}</label>
          <input id="blue-light-filter-range" type="range" min="0" max="1" step="0.01" />
      </div>
    `
  }
}

export {
  blueLightFilter,
  blueLightFilterClass
}
