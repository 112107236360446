import toggleVisibility from "./assets/toggle-visibility.svg";
import toggleWcag from "./assets/toggle-wcag.svg";
import advertisement from "./assets/advertisement.svg";

import autoContrastMode from "./assets/auto-contrast-mode.svg";

import contrastMode from "./assets/contrast-mode.svg";

import darkMode from "./assets/dark-mode.svg";

import blueLightFilter from "./assets/blue-light-filter.svg";

import grayScale from "./assets/gray-scale.svg";

import saturationMode from "./assets/saturation-mode.svg";

import redColorBlindness from "./assets/red-color-blindness.svg";
import redColorBlindnessFilter from "./assets/red-color-blindness-filter.svg";

import greenColorBlindness from "./assets/green-color-blindness.svg";
import greenColorBlindnessFilter from "./assets/green-color-blindness-filter.svg";

import blueColorBlindness from "./assets/blue-color-blindness.svg";
import blueColorBlindnessFilter from "./assets/blue-color-blindness-filter.svg";

import fontVisibility from "./assets/font-visibility.svg";
import fontVisibilitySize from "./assets/font-visibility-size.svg";
import fontVisibilityLineSpacing from "./assets/font-visibility-line-spacing.svg";
import fontVisibilityLetterSpacing from "./assets/font-visibility-letter-spacing.svg";
import fontVisibilityCharacterSpacing from "./assets/font-visibility-character-spacing.svg";
import fontVisibilityReadableFont from "./assets/font-visibility-readable-font.svg";

import biggerCursor from "./assets/bigger-cursor.svg";
import biggerCursorSize from "./assets/bigger-cursor-size.svg";
import biggerCursorNormal from "./assets/bigger-cursor-normal.svg";
import biggerCursorPointer from "./assets/bigger-cursor-pointer.svg";
import biggerCursorText from "./assets/bigger-cursor-text.svg";

import close from "./assets/close.svg";
import reset from "./assets/reset.svg";
import settings from "./assets/settings.svg";

export default {
  "head": {
    "toggle-visibility": toggleVisibility,
    "toggle-wcag": toggleWcag,
    "advertisement": advertisement
  },

  "functions": {
    "auto-contrast-mode": autoContrastMode,

    "contrast-mode": contrastMode,

    "dark-mode": darkMode,

    "blue-light-filter": blueLightFilter,

    "gray-scale": grayScale,

    "saturation-mode": saturationMode,

    "color-blindness": {
      "red": {
        "icon": redColorBlindness,
        "filter": redColorBlindnessFilter
      },
      "green": {
        "icon": greenColorBlindness,
        "filter": greenColorBlindnessFilter
      },
      "blue": {
        "icon": blueColorBlindness,
        "filter": blueColorBlindnessFilter
      }
    },

    "font-visibility": {
      "icon": fontVisibility,
      "size": fontVisibilitySize,
      "line-spacing": fontVisibilityLineSpacing,
      "letter-spacing": fontVisibilityLetterSpacing,
      "character-spacing": fontVisibilityCharacterSpacing,
      "readable-font":  fontVisibilityReadableFont
    },

    "bigger-cursor": {
      "icon": biggerCursor,
      "size": biggerCursorSize,
      "normal": biggerCursorNormal,
      "pointer": biggerCursorPointer,
      "text": biggerCursorText
    }
  },

  "settings": {
    "close": close,
    "reset": reset,
    "settings": settings
  }
}
