import icons from "@/icons";
import { fwWcagType } from "@/types";

function saturationMode() {
  const name = window.fwWcag.functions.wcag["saturation-mode"].name;
  const input = window.fwWcag["global-variables"].shadow.querySelector(`#settings-${name} #${name}-range`);
  const controller = new AbortController();
  const documentElement = document.documentElement;

  // set Attribute for removing filter afterwards
  documentElement.setAttribute(`data-fw-wcag-filter-${name}`, "true");

  // set saturation value
  documentElement.style.filter = `saturate(${window.fwWcag["user-config"].functions[name].settings.value})`;

  // Add event listener for dynamic changes
  input.addEventListener("input", (field: {
    target: {
      value: number
    }
  }) => {
      // set new background opacity
      documentElement.style.filter = `saturate(${field.target.value})`;
  }, { signal: controller.signal });

  // Add controller to event listeners object
  window.fwWcag["global-variables"]["event-listeners"][`function-${name}`] = {
      input: controller
  }
}


class saturationModeClass {
  [`name`]: string
  [`function`]: fwWcagType[`methods`][`wcag-functions`][`saturation-mode`][`function`]
  [`settings-function`]: fwWcagType[`methods`][`wcag-functions`][`saturation-mode`][`settings`]
  [`observer`]: boolean
  [`mobile`]: boolean
  [`conflict`]: string[]
  [`settings`]: string
  [`icon`]: string
  [`de`]: {
    [`title`]: string
    [`settings-description`]: string
  }
  [`en`]: {
    [`title`]: string
    [`settings-description`]: string
  }

  constructor(instance: fwWcagType) {
    this[`name`] = `saturation-mode`
    this[`function`] = instance[`methods`][`wcag-functions`][`saturation-mode`][`function`]
    this[`settings-function`] = instance[`methods`][`wcag-functions`][`saturation-mode`][`settings`]
    this[`observer`] = false
    this[`mobile`] = true
    this[`conflict`] = [
      `gray-scale`,
      `blue-light-filter`,
      `contrast-mode`,
      `red-color-blindness`,
      `green-color-blindness`,
      `blue-color-blindness`
    ]
    this[`icon`] = icons[`functions`][`saturation-mode`],
      this[`de`] = {
        [`title`]: `Sättigungs-Modus`,
        [`settings-description`]: `Intensität`
      }
    this[`en`] = {
      [`title`]: `Saturation-Mode`,
      [`settings-description`]: `Intensity`
    }
    this[`settings`] = `
      <div class="input-range-wrapper">
          <label for="saturation-mode-range">${this[instance[`defaults`][`user-config`][`lang`]][`settings-description`]}</label>
          <input id="saturation-mode-range" type="range" min="0" max="1" step="0.01" />
      </div>
    `
  }
}

export {
  saturationMode,
  saturationModeClass
}
