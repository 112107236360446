import icons from "@/icons";
import { fwWcagType, stylesType, elementOverrideStylesType, fontVisibilityFieldType } from "@/types";

function fontVisibilty() {
  const name = window.fwWcag.functions.wcag["font-visibility"].name;
  const excludedTagNames = new Set(["SCRIPT", "LINK", "HEAD", "HTML", "META", "TITLE", "STYLE"]);
  const fontVisibilityFields: Record<string, fontVisibilityFieldType> = {
    "size": {
      input: window.fwWcag["global-variables"].shadow.querySelector(`#settings-${name} #font-visibility-range-size`),
      computedStyleName: "fontSize",
      style: "font-size",
    },
    "line-spacing": {
      input: window.fwWcag["global-variables"].shadow.querySelector(`#settings-${name} #font-visibility-range-line-spacing`),
      computedStyleName: "lineHeight",
      style: "line-height"
    },
    "letter-spacing": {
      input: window.fwWcag["global-variables"].shadow.querySelector(`#settings-${name} #font-visibility-range-letter-spacing`),
      computedStyleName: "wordSpacing",
      style: "word-spacing"
    },
    "character-spacing": {
      input: window.fwWcag["global-variables"].shadow.querySelector(`#settings-${name} #font-visibility-range-character-spacing`),
      computedStyleName: "letterSpacing",
      style: "letter-spacing"
    },
    "readable-font": {
      input: window.fwWcag["global-variables"].shadow.querySelector(`#settings-${name} #font-visibility-checkbox-readable-font`),
      computedStyleName: "fontFamily",
      style: "font-family",
      property: "\"Arial\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",\"Roboto\",Helvetica,Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\""
    }
  }
  const selector = document.body;
  let targetNodes: HTMLElement[];

  async function updateSelectors() {
    // Create array of elements that should be modified
    targetNodes = Array.prototype.slice.call(selector.querySelectorAll(`*:not([data-fw-wcag-styles-id-${name}], [id^=data-fw-wcag-styles-id-${name}])`));
    if (!selector.getAttribute(`[data-fw-wcag-styles-id-${name}]`) && (!selector.getAttribute("id")?.includes(`data-fw-wcag-styles-id-${name}`) || !selector.getAttribute("id"))) {
      targetNodes.push(selector);
    }

    // Remove all elements inside fw-wcag
    const fwWcagElements = Array.prototype.slice.call(document.querySelectorAll("#fw-wcag-wrapper"));
    return targetNodes = targetNodes.filter(value => !fwWcagElements.includes(value));
  }

  async function updateStyles() {
    const style = document.createElement('style');
    style.classList.add(`fw-wcag-styles-${name}`);
    const styles: stylesType[] = [];
    let css = "";

    console.log(targetNodes);

    // Create styles
    for (const [key, element] of targetNodes.entries()) {
      if (!excludedTagNames.has(element.tagName)) {
        const uniqId = key + Date.now();
        const elementId = element.getAttribute("id") ?? false;
        const elementOverrideStyles: elementOverrideStylesType = {
          selector: `html ${element.tagName.toLowerCase()}${elementId ? `${elementId && !elementId.includes("data-fw-wcag-styles-id") ? `#${elementId}` : ""}[data-fw-wcag-styles-id-${name}='${uniqId}']` : `#data-fw-wcag-styles-id-${name}-${uniqId}`}`,
          styles: []
        }

        // Set unique selector
        elementId ? element.setAttribute(`data-fw-wcag-styles-id-${name}`, String(uniqId)) : element.setAttribute("id", `data-fw-wcag-styles-id-${name}-${uniqId}`);

        const elementStyles: CSSStyleDeclaration = getComputedStyle(element);

        function addSingleStyle(type: keyof typeof fontVisibilityFields) {
          const customStyleProperty = elementStyles[fontVisibilityFields[type].computedStyleName];
          const style = fontVisibilityFields[type].style;
          const property = fontVisibilityFields[type].property;

          if (property) {
            if (window.fwWcag["user-config"].functions[name].settings[type].value) {
              elementOverrideStyles.styles.push(`${style}: ${property} !important;`);
            }
          } else {
            const value = customStyleProperty === "normal" || customStyleProperty === "0px" ? 1 : Number.parseFloat(String(customStyleProperty).replace("px", ""));
            const finalValue = value * window.fwWcag["user-config"].functions[name].settings[type].value;
            elementOverrideStyles.styles.push(`${style}: ${(value === 1 ? finalValue - 1 : finalValue)}px !important;`);
          }
        }

        // iterate through array and set styles
        for (const [key] of Object.entries(fontVisibilityFields)) {
          addSingleStyle(key);
        }

        // Append Element styles to overall styles
        styles.push(elementOverrideStyles);
      }
    }

    // Create stylesheet
    for (const item of styles) {
      let itemStyles = "";
      for (const style of item.styles) {
        itemStyles += style;
      }

      css += `${item.selector} { ${itemStyles} }`;
    }

    // Append stylesheet
    if (css) {
      style.append(document.createTextNode(css));
      document.head.append(style);
    }
  }

  // Add sub-functions to array
  window.fwWcag.functions.wcag[name]["sub-functions"]["update-selectors"] = updateSelectors;
  window.fwWcag.functions.wcag[name]["sub-functions"]["update-styles"] = updateStyles;

  // Add selectors
  updateSelectors().then(() => {
    // Add styles
    updateStyles();
  })
}

class fontVisibilityClass {
  [`name`]: string
  [`function`]: fwWcagType[`methods`][`wcag-functions`][`font-visibility`][`function`]
  [`function-parameters`]: HTMLElement
  [`settings-function`]: fwWcagType[`methods`][`wcag-functions`][`font-visibility`][`settings`]
  [`sub-functions`]: object
  [`observer`]: boolean
  [`mobile`]: boolean
  [`conflict`]: string[]
  [`settings`]: string
  [`icon`]: string
  [`de`]: {
    [`title`]: string
    [`settings`]: {
      [`size`]: {
        [`label`]: string
      },
      [`line-spacing`]: {
        [`label`]: string
      },
      [`letter-spacing`]: {
        [`label`]: string
      },
      [`character-spacing`]: {
        [`label`]: string
      },
      [`readable-font`]: {
        [`label`]: string
      }
    }
  }
  [`en`]: {
    [`title`]: string
    [`settings`]: {
      [`size`]: {
        [`label`]: string
      },
      [`line-spacing`]: {
        [`label`]: string
      },
      [`letter-spacing`]: {
        [`label`]: string
      },
      [`character-spacing`]: {
        [`label`]: string
      },
      [`readable-font`]: {
        [`label`]: string
      }
    }
  }

  constructor(instance: fwWcagType) {
    this[`name`] = `font-visibility`
    this[`function`] = instance[`methods`][`wcag-functions`][`font-visibility`][`function`]
    this[`function-parameters`] = document.body
    this[`settings-function`] = instance[`methods`][`wcag-functions`][`font-visibility`][`settings`]
    this[`sub-functions`] = {}
    this[`observer`] = true
    this[`mobile`] = true
    this[`conflict`] = []
    this[`icon`] = icons[`functions`][`font-visibility`][`icon`]
    this[`de`] = {
      [`title`]: `Schrift`,
      [`settings`]: {
        [`size`]: {
          [`label`]: `Schriftgröße`,
        },
        [`line-spacing`]: {
          [`label`]: `Zeilenabstand`,
        },
        [`letter-spacing`]: {
          [`label`]: `Wortabstand`,
        },
        [`character-spacing`]: {
          [`label`]: `Zeichenabstand`,
        },
        [`readable-font`]: {
          [`label`]: `Leserliche Schriftart`,
        }
      }
    }
    this[`en`] = {
      [`title`]: `Font`,
      [`settings`]: {
        [`size`]: {
          [`label`]: `Font Size`,
        },
        [`line-spacing`]: {
          [`label`]: `Line Spacing`,
        },
        [`letter-spacing`]: {
          [`label`]: `Letter Spacing`,
        },
        [`character-spacing`]: {
          [`label`]: `Character Spacing`,
        },
        [`readable-font`]: {
          [`label`]: `Readable Font`,
        },
      }
    }
    this[`settings`] = `
      <div class="settings-font-visibility">
          <div class="input-range-wrapper">
              ${icons[`functions`][`font-visibility`][`size`]}
              <label for="font-visibility-size">${this[instance[`defaults`][`user-config`][`lang`]][`settings`][`size`][`label`]}</label>
              <input id="font-visibility-size" type="range" min="1" max="2" step="0.1" />
          </div>
          <div class="input-range-wrapper">
              ${icons[`functions`][`font-visibility`][`line-spacing`]}
              <label for="font-visibility-line-spacing">${this[instance[`defaults`][`user-config`][`lang`]][`settings`][`line-spacing`][`label`]}</label>
              <input id="font-visibility-line-spacing" type="range" min="1" max="5" step="0.4" />
          </div>
          <div class="input-range-wrapper">
              ${icons[`functions`][`font-visibility`][`letter-spacing`]}
              <label for="font-visibility-letter-spacing">${this[instance[`defaults`][`user-config`][`lang`]][`settings`][`letter-spacing`][`label`]}</label>
              <input id="font-visibility-letter-spacing" type="range" min="1" max="11" step="1" />
          </div>
          <div class="input-range-wrapper">
              ${icons[`functions`][`font-visibility`][`character-spacing`]}
              <label for="font-visibility-character-spacing">${this[instance[`defaults`][`user-config`][`lang`]][`settings`][`character-spacing`][`label`]}</label>
              <input id="font-visibility-character-spacing" type="range" min="1" max="5" step="0.4" />
          </div>
          <div class="input-checkbox-wrapper">
              ${icons[`functions`][`font-visibility`][`readable-font`]}
              <label for="font-visibility-readable-font">${this[instance[`defaults`][`user-config`][`lang`]][`settings`][`readable-font`][`label`]}</label>
              <input id="font-visibility-readable-font" type="checkbox" />
          </div>
      </div>
    `
  }
}

export {
  fontVisibilty,
  fontVisibilityClass
}
