export default function initializeWcagFunction(functionName: string) {
  // Remove all transitions
  window.fwWcag.methods["helper-functions"]["disable-transitions"](document.body);

  // Initialize function
  window.fwWcag.functions.wcag[functionName].function(window.fwWcag.functions.wcag[functionName]["function-parameters"]);
  window.fwWcag.functions.wcag[functionName].observer ? window.fwWcag.methods["initialization-functions"]["initialize-observer"](window.fwWcag.functions.wcag[functionName].name) : "";

  // Add transitions back
  window.fwWcag.methods["helper-functions"]["enable-transitions"]();
}
